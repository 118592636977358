import * as React from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function CheckEmail() {
  const navigate = useNavigate();

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Check Your Email
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1">
            A verification email has been sent to your email address. Please check your inbox and follow the instructions to complete your registration.
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/first_signin')}
          >
            Go to Sign In
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
