/**
 * Return an array of training type objects with id and label.
 * @returns {Array<{ id: number, label: string }>} Array of training types.
 */
export const getTrainingTypesArray = () => [
  { id: 0, label: 'כדורגל קבוצתי' },
  { id: 1, label: 'כדורגל פרטי' },
  { id: 2, label: 'כדורגל זוגי' },
  { id: 3, label: 'תזונאי/ת' },
  { id: 4, label: 'תזונאי/ת קבוצתי' },
  { id: 5, label: 'מנטאלי' },
  { id: 6, label: 'מנטאלי זוגי' },
  { id: 7, label: 'אימון כוח' },
  { id: 8, label: 'אימון כוח קבוצתי' },
];

export const getTrainingTypesByTrainingType = (trainingType) => {
  const allTrainingTypes = getTrainingTypesArray(); // Get all training types
  const filteredTypes = allTrainingTypes.filter(type => type.id === trainingType); // Match by ID
  return filteredTypes;
};

/**
 * Return the label for the training type.
 * @param {number} trainingType - The training type identifier.
 * @returns {string} The label for the training type.
 */
export const getTrainingType = (trainingType) => {
  switch (trainingType) {
    case 0:
      return 'כדורגל קבוצתי'; // Group
    case 1:
      return 'כדורגל פרטי'; // Private
    case 2:
      return 'כדורגל זוגי'; // Two trainees
    case 3:
      return 'תזונאי/ת'; // Dietitian
    case 4:
      return 'תזונאי/ת קבוצתי'; // Group dietitian
    case 5:
      return 'מנטאלי'; // Mental
    case 6:
      return 'מנטאלי זוגי'; // Mental duo
    case 7:
      return 'אימון כוח'; // Strength training
    case 8:
      return 'אימון כוח קבוצתי'; // Group strength training
    default:
      return 'לא ידוע'; // Unknown or default label
  }
};

/**
 * Format a time string (HH:mm:ss) into a readable format (HH:mm).
 * @param {string} timeString - The time string to format.
 * @returns {string} The formatted time string.
 */
export const formatTime = (timeString) => {
  const [hours, minutes] = timeString.split(':');
  return `${hours}:${minutes}`;
};

/**
 * Format a date string (YYYY-MM-DD) into a readable format (DD/MM/YYYY).
 * @param {string} dateString - The date string to format.
 * @returns {string} The formatted date string.
 */
export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};
