import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { formatDate, formatTime, getTrainingType } from '../utils'; // Assume you have these functions
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles'; // Import useTheme for access to theme

const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

const CardDetails = ({ userId, token, cardId }) => {
  const navigate = useNavigate();
  const theme = useTheme(); // Access the theme for color adjustments

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [cardDetails, setCardDetails] = useState(null);
  const [cardTrainings, setCardTrainings] = useState([]);
  const [cardPayments, setCardPayments] = useState([]);

  useEffect(() => {
    const fetchCardDetails = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/user_card_details', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id: userId, token, card_id: cardId }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        if (data.success) {
          if (isDebugMode) console.log('data:', data);
          setCardDetails(data.card_data.card_details || null);
          setCardTrainings(data.card_data.trainings || []);
          setCardPayments(data.card_data.payments || []);
        } else {
          navigate('/login');
        }
      } catch (error) {
        navigate('/login');
      } finally {
        setLoading(false);
      }
    };

    fetchCardDetails();
  }, [userId, token, cardId]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box>
      {/* Card Details Table */}
      <TableContainer component={Paper} sx={{ marginBottom: 4, backgroundColor: 'transparent' }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell sx={{ textAlign: 'right', borderBottom: `2px solid ${theme.palette.primary.main}`, color: theme.palette.primary.contrastText }}>
                {formatDate(cardDetails.creation_time)}
              </TableCell>
              <TableCell sx={{ textAlign: 'right', borderBottom: `2px solid ${theme.palette.primary.main}`, color: theme.palette.primary.contrastText }}>
                {getTrainingType(cardDetails.training_type)}
              </TableCell>
              <TableCell sx={{ textAlign: 'right', borderBottom: `2px solid ${theme.palette.primary.main}`, color: theme.palette.primary.contrastText }}>
                נוצל {cardDetails.lessons_used}
              </TableCell>
              <TableCell sx={{ textAlign: 'right', borderBottom: `2px solid ${theme.palette.primary.main}`, color: theme.palette.primary.contrastText }}>
                סהכ אימונים {cardDetails.lessons_count}
              </TableCell>
              <TableCell sx={{ textAlign: 'right', borderBottom: `2px solid ${theme.palette.primary.main}`, color: theme.palette.primary.contrastText }}>
                עלות {cardDetails.price}
              </TableCell>
              <TableCell sx={{ textAlign: 'right', borderBottom: `2px solid ${theme.palette.primary.main}`, color: theme.palette.primary.contrastText }}>
                שולם {cardDetails.amount_paid}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {/* Card Trainings Table */}
      <Typography variant="h6" gutterBottom sx={{ marginTop: 4, textAlign: 'right', color: theme.palette.primary.contrastText }}>
        אימונים
      </Typography>
      <TableContainer component={Paper} sx={{ backgroundColor: 'transparent' }}>
        <Table>
          <TableBody>
            {cardTrainings.length > 0 ? (
              cardTrainings.map((training) => (
                <TableRow key={training.session_id}>
                  <TableCell sx={{ textAlign: 'right', borderBottom: `2px solid ${theme.palette.primary.main}`, color: theme.palette.primary.contrastText }}>
                    {formatDate(training.date)}
                  </TableCell>
                  <TableCell sx={{ textAlign: 'right', borderBottom: `2px solid ${theme.palette.primary.main}`, color: theme.palette.primary.contrastText }}>
                    {formatTime(training.time)}
                  </TableCell>
                  <TableCell sx={{ textAlign: 'right', borderBottom: `2px solid ${theme.palette.primary.main}`, color: theme.palette.primary.contrastText }}>
                    {training.trainee_name}
                  </TableCell>
                  <TableCell sx={{ textAlign: 'right', borderBottom: `2px solid ${theme.palette.primary.main}`, color: theme.palette.primary.contrastText }}>
                    {training.location}
                  </TableCell>
                  <TableCell sx={{ textAlign: 'right', borderBottom: `2px solid ${theme.palette.primary.main}`, color: theme.palette.primary.contrastText }}>
                    {training.description}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} sx={{ textAlign: 'center', color: theme.palette.primary.contrastText }}>
                  אין אימונים להצגה
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Card Payments Table */}
      <Typography variant="h6" gutterBottom sx={{ marginTop: 4, textAlign: 'right', color: theme.palette.primary.contrastText }}>
        היסטוריית תשלומים
      </Typography>
      <TableContainer component={Paper} sx={{ backgroundColor: 'transparent' }}>
        <Table>
          <TableBody>
            {cardPayments.length > 0 ? (
              cardPayments.map((payment, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ textAlign: 'right', borderBottom: `2px solid ${theme.palette.primary.main}`, color: theme.palette.primary.contrastText }}>
                    {payment.type_paid}
                  </TableCell>
                  <TableCell sx={{ textAlign: 'right', borderBottom: `2px solid ${theme.palette.primary.main}`, color: theme.palette.primary.contrastText }}>
                    {formatDate(payment.creation_date)}
                  </TableCell>
                  <TableCell sx={{ textAlign: 'right', borderBottom: `2px solid ${theme.palette.primary.main}`, color: theme.palette.primary.contrastText }}>
                    {payment.pay}
                  </TableCell>
                  <TableCell sx={{ textAlign: 'right', borderBottom: `2px solid ${theme.palette.primary.main}`, color: theme.palette.primary.contrastText }}>
                    {payment.descriptions}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} sx={{ textAlign: 'center', color: theme.palette.primary.contrastText }}>
                  אין היסטוריית תשלומים
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CardDetails;
