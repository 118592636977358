import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import myLogo from '../ez.jpeg'; // Adjust the path based on your project structure

const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

export default function SignIn() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password');
    callLoginForAdmin(email, password);

    if (isDebugMode) console.log({ email, password });
  };

  const callLoginForAdmin = async (email, password) => {
    if (isDebugMode) console.log("hi from callLoginForAdmin");

    try {
      const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/admin_login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
      const jsonResponse = await response.json();

      if (isDebugMode) console.log(jsonResponse);

      if (jsonResponse.success) {
        navigate('/admin_trainings', { state: { adminId: jsonResponse.admin_id, token: jsonResponse.token } });
      } else {
        if (isDebugMode) console.error("wrong input");
      }
    } catch (error) {
      if (isDebugMode) console.error('Error:', error);
    }
  };

  const handleForgotPassword = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSendResetLink = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/reset_admin_password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      const jsonResponse = await response.json();

      if (jsonResponse.success) {
        if (isDebugMode) console.log("Reset link sent to email");
      } else {
        if (isDebugMode) console.error("Failed to send reset link");
      }
    } catch (error) {
      if (isDebugMode) console.error('Error:', error);
    }
    setOpen(false);
  };

  const handleSignUp = () => {
    navigate('/signup_admin');
  };

return (
  <div>
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar
          sx={{ m: 1, bgcolor: 'secondary.main', width: 100, height: 100 }}
          alt="Logo"
          src={myLogo}
        />
        <Typography component="h1" variant="h5">
          Admin Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2" onClick={handleForgotPassword}>
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2" onClick={handleSignUp}>
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>

    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Reset Password</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter your email address. We will send you an email to reset your password.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="reset-email"
          label="Email Address"
          type="email"
          fullWidth
          variant="standard"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSendResetLink}>Send</Button>
      </DialogActions>
    </Dialog>
  </div>
);

}
