import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useLocation as useRouterLocation, useNavigate } from 'react-router-dom';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import AddTrainerDialog from './AddTrainerDialog'; // Import the new dialog component
import NavBar from '../NavBar'; // Import NavBar
import { useTheme } from '@mui/material/styles'; // Import useTheme for access to theme

import myLogo from '../../ez.jpeg'; // Adjust the path based on your project structure

const isDebugMode = process.env.REACT_APP_DEBUG === 'true';


// Helper function to fetch user info
const getUserInfo = async (userId, token) => {
  try {
    const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/user_info', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_id: userId, token: token }), // Use user_id and token for fetch
    });
    const jsonResponse = await response.json();
    if (isDebugMode) console.log(jsonResponse);
    if (jsonResponse.success) {
      return jsonResponse.user_info || []; // Return array if available
    } else {
      if (isDebugMode) console.error("Error fetching user info");
    }
  } catch (error) {
    if (isDebugMode) console.error('Error:', error);
  }
  return [];
};

const getUniformOrders = async (userId, token) => {
  try {
    const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/get_order_uniform', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_id: userId, token: token }),
    });
    const jsonResponse = await response.json();
    if (jsonResponse.success) {
      return jsonResponse.orders || []; // Return array if available
    } else {
      if (isDebugMode) console.error("Error getting uniform orders");
    }
  } catch (error) {
    if (isDebugMode) console.error('Error:', error);
  }
  return [];
};


// Helper function to fetch trainers
const getTrainers = async (userId, token) => {
  try {
    const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/get_trainees', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_id: userId, token: token }), // Use user_id and token for fetch
    });
    const jsonResponse = await response.json();
    if (jsonResponse.success) {
      return jsonResponse.trainers || []; // Return array if available
    } else {
      if (isDebugMode) console.error("Error getting trainers");
    }
  } catch (error) {
    if (isDebugMode) console.error('Error:', error);
  }
  return [];
};

// Helper function to add a new trainer
const addTrainer = async (trainer) => {
  try {
    const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/add_trainer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(trainer),
    });
    const jsonResponse = await response.json();
    if (jsonResponse.success) {
      return true;
    } else {
      if (isDebugMode) console.error("Error adding trainer");
    }
  } catch (error) {
    if (isDebugMode) console.error('Error:', error);
  }
  return false;
};

function UserInfo() {
  const theme = useTheme(); // Access the theme for color adjustments
  const navigate = useNavigate();
  const currentLocation = useRouterLocation();
  const { userId, token } = currentLocation.state || {}; // Extract userId and token
  const [userInfo, setUserInfo] = useState([]);
  const [trainers, setTrainers] = useState([]); // State for trainers
  const [dialogOpen, setDialogOpen] = useState(false);
  const [open, setOpen] = useState(false); // State for dialog open

  const [newTrainer, setNewTrainer] = useState({ name: '', phone: '', team: '' }); // State for new trainer details
  const [tabIndex, setTabIndex] = useState(3); // Default tab index for 'User Info'
  const [openUniformDialog, setOpenUniformDialog] = useState(false);
  const [uniformOrders, setUniformOrders] = useState([]);

const [uniformItems] = useState([{ id: 1, name: 'חולצה' }, { id: 2, name: 'מכנסיים' }, { id: 3, name: 'גרביים' }]);
const [uniformItemsSizes] = useState(['S', 'M', 'L', 'XL']); // Sizes for shirts and pants
const [socks] = useState(Array.from({ length: 14 }, (_, i) => (32 + i).toString())); // Sock sizes from 32 to 45
const [uniformOrder, setUniformOrder] = useState({
    shirt: { size: 'None', quantity: 0 },
    pants: { size: 'None', quantity: 0 },
    socks: { size: 'None', quantity: 0 },
});

const shirtPrice = 70;
const pantsPrice = 70;
const socksPrice = 30;
const fullSetPrice = 150;

const calculateTotal = () => {
    let total = 0;

    // Count the number of complete sets
    const completeSets = Math.min(
        uniformOrder.shirt.size !== "None" ? uniformOrder.shirt.quantity : 0,
        uniformOrder.pants.size !== "None" ? uniformOrder.pants.quantity : 0,
        uniformOrder.socks.size !== "None" ? uniformOrder.socks.quantity : 0
    );

    // Add price for complete sets
    total += completeSets * fullSetPrice;

    // Calculate remaining items
    if (uniformOrder.shirt.size !== "None") {
        total += (uniformOrder.shirt.quantity - completeSets) * shirtPrice;
    }
    if (uniformOrder.pants.size !== "None") {
        total += (uniformOrder.pants.quantity - completeSets) * pantsPrice;
    }
    if (uniformOrder.socks.size !== "None") {
        total += (uniformOrder.socks.quantity - completeSets) * socksPrice;
    }

    return total;
};

  useEffect(() => {
    const fetchData = async () => {
      const user = await getUserInfo(userId, token);
      if (!user.length) { // Check if user info is not fetched correctly
        navigate('/login'); // Redirect to login if user info is invalid
        return;
      }
      setUserInfo(user);
      const fetchedTrainers = await getTrainers(userId, token);
      setTrainers(fetchedTrainers);

      const fetchedOrders = await getUniformOrders(userId, token);
      setUniformOrders(fetchedOrders);
    };
    fetchData();
  }, [userId, token,navigate]);

  const handleOpenDialog = () => setOpen(true);
  const handleCloseDialog = () => setOpen(false);

    const handleAddTrainer = async (trainer) => {
        console.log('Trainer to add:', trainer);
        await addTrainer({ ...trainer, user_id: userId, token: token });
        setDialogOpen(false);
        // Reload trainers from server
        const fetchedTrainers = await getTrainers(userId, token);
        setTrainers(fetchedTrainers);
    };

const handleOrderUniform = async (order) => {
    // Here, 'order' will contain all the data from the dialog
    if (isDebugMode) console.log("Order details:", order);
    if (isDebugMode) console.log("userid:", userId);
    if (isDebugMode) console.log("token:", token);
    // Proceed with the ordering logic
    const totalPrice = calculateTotal(order);
    // Handle payment or further processing with totalPrice and order details
    if (isDebugMode) console.log("Total Price:", totalPrice);

    try {
      const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/add_order_uniform', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_id: userId, token: token, order: order, total_price: totalPrice}), // Use user_id and token for fetch
    });
    const jsonResponse = await response.json();
    if (jsonResponse.success) {
      return true;
    } else {
      if (isDebugMode) console.error("Error pay_order_uniform");
    }
  } catch (error) {
    if (isDebugMode) console.error('Error:', error);
  }

};


const handleCancelOrder = async (orderId) => {
  try {
      if (isDebugMode) console.error('handleCancelOrder: userId', userId);
      if (isDebugMode) console.error('handleCancelOrder: token', token);
      if (isDebugMode) console.error('handleCancelOrder: orderId', orderId);

    const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/cancel_order_uniform', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_id: userId, token: token, order_id: orderId }), // Use user_id, token, and order_id for fetch
    });

    const jsonResponse = await response.json();
    if (jsonResponse.success) {
      // Reload orders from server or update state to reflect cancellation
      const fetchedOrders = await getUniformOrders(userId, token);
      setUniformOrders(fetchedOrders);
    } else {
      if (isDebugMode) console.error("Error canceling order");
    }
  } catch (error) {
    if (isDebugMode) console.error('Error:', error);
  }
};


  return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>

      <CssBaseline />
      <Container
        component="main"
        maxWidth="md"
        className="users-page"
        sx={{ flex: 1, overflowY: 'auto', paddingBottom: 80 }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 8 }}>
          <Avatar
            sx={{ m: 1, bgcolor: 'secondary.main', width: 100, height: 100 }} // Adjust size as needed
            alt="Logo"
            src={myLogo}
          />
          <Typography component="h1" variant="h5" sx={{ color: theme.palette.primary.contrastText }}>
            {/* Any other title text */}
          </Typography>

          <Box sx={{ width: '100%', maxWidth: 600 }}>
            {/* User Info Table */}
            <Box sx={{ maxHeight: 300, overflowY: 'auto' }}>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                  <tr>
                    <th style={{ padding: '8px', borderBottom: `1px solid ${theme.palette.primary.main}`, textAlign: 'center' }}>
                      <Typography sx={{ color: theme.palette.primary.contrastText }}>שם</Typography>
                    </th>
                    <th style={{ padding: '8px', borderBottom: `1px solid ${theme.palette.primary.main}`, textAlign: 'center' }}>
                      <Typography sx={{ color: theme.palette.primary.contrastText }}>אימייל</Typography>
                    </th>
                    <th style={{ padding: '8px', borderBottom: `1px solid ${theme.palette.primary.main}`, textAlign: 'center' }}>
                      <Typography sx={{ color: theme.palette.primary.contrastText }}>טלפון</Typography>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {userInfo.length > 0 ? (
                    userInfo.map(user => (
                      <tr key={user.id}>
                        <td style={{ padding: '8px', borderBottom: `1px solid ${theme.palette.primary.main}`, textAlign: 'center' }}>
                          <Typography sx={{ color: theme.palette.primary.contrastText }}>{user.name || 'N/A'}</Typography>
                        </td>
                        <td style={{ padding: '8px', borderBottom: `1px solid ${theme.palette.primary.main}`, textAlign: 'center' }}>
                          <Typography sx={{ color: theme.palette.primary.contrastText }}>{user.email || 'N/A'}</Typography>
                        </td>
                        <td style={{ padding: '8px', borderBottom: `1px solid ${theme.palette.primary.main}`, textAlign: 'center' }}>
                          <Typography sx={{ color: theme.palette.primary.contrastText }}>{user.phone || 'N/A'}</Typography>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3" style={{ padding: '8px', borderBottom: `1px solid ${theme.palette.primary.main}`, textAlign: 'center' }}>
                        <Typography sx={{ color: theme.palette.primary.contrastText }}>אין מידע על המשתמש.</Typography>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Box>
          </Box>

          <Box sx={{ width: '100%', maxWidth: 600, marginTop: 4 }}>
  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
    <Typography variant="h6" sx={{ color: theme.palette.primary.contrastText }}></Typography>

    <Button
      variant="contained"
      color="primary"
      onClick={handleOpenDialog}
    >
      הוספת מתאמן
    </Button>

    <AddTrainerDialog
      open={open}
      onClose={handleCloseDialog}
      onAddTrainer={handleAddTrainer}
    />
  </Box>

  {/* Trainers Table */}
  <Box sx={{ maxHeight: '400px', overflowY: 'auto', display: 'block', paddingBottom: 20}}>
    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
      <thead>
        <tr>
          <th style={{ padding: '8px', borderBottom: `1px solid ${theme.palette.primary.main}`, textAlign: 'center' }}>
            <Typography sx={{ color: theme.palette.primary.contrastText }}>שם</Typography>
          </th>
          <th style={{ padding: '8px', borderBottom: `1px solid ${theme.palette.primary.main}`, textAlign: 'center' }}>
            <Typography sx={{ color: theme.palette.primary.contrastText }}>טלפון</Typography>
          </th>
          <th style={{ padding: '8px', borderBottom: `1px solid ${theme.palette.primary.main}`, textAlign: 'center' }}>
            <Typography sx={{ color: theme.palette.primary.contrastText }}>קבוצה</Typography>
          </th>
          <th style={{ padding: '8px', borderBottom: `1px solid ${theme.palette.primary.main}`, textAlign: 'center' }}>
            <Typography sx={{ color: theme.palette.primary.contrastText }}>שנתון</Typography>
          </th>
        </tr>
      </thead>
      <tbody>
        {trainers.length > 0 ? (
          trainers.map((trainer, index) => (
            <tr key={trainer.id}>
              <td style={{ padding: '8px', borderBottom: `1px solid ${theme.palette.primary.main}`, textAlign: 'center' }}>
                <Typography sx={{ color: theme.palette.primary.contrastText }}>{trainer.name}</Typography>
              </td>
              <td style={{ padding: '8px', borderBottom: `1px solid ${theme.palette.primary.main}`, textAlign: 'center' }}>
                <Typography sx={{ color: theme.palette.primary.contrastText }}>{trainer.phone}</Typography>
              </td>
              <td style={{ padding: '8px', borderBottom: `1px solid ${theme.palette.primary.main}`, textAlign: 'center' }}>
                <Typography sx={{ color: theme.palette.primary.contrastText }}>{trainer.team}</Typography>
              </td>
              <td style={{ padding: '8px', borderBottom: `1px solid ${theme.palette.primary.main}`, textAlign: 'center' }}>
                <Typography sx={{ color: theme.palette.primary.contrastText }}>{trainer.year}</Typography>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="4" style={{ padding: '8px', textAlign: 'center' }}>
              <Typography sx={{ color: theme.palette.primary.contrastText }}>לא נמצאו מתאמנים.</Typography>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  </Box>
</Box>


        </Box>
      </Container>
          {/* NavBar Component */}
      <NavBar userId={userId} token={token} tabIndex={tabIndex} setTabIndex={setTabIndex} sx={{ position: 'fixed', bottom: 0, width: '100%' }} />
    </Box>
  );
}

export default UserInfo;