import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import myLogo from '../ez.jpeg'; // Adjust the path based on your project structure

const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

export default function SignIn() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [emailError, setEmailError] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    // Reset error states
    setEmailError(false);
    setPasswordError(false);

    // Email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setEmailError(true);
      return;
    }

    // Password validation (e.g., at least 6 characters)
    if (password.length < 6) {
      if (isDebugMode) console.log("Password validation at least 6 characters");
//      setPasswordError(true);
//      return;
    }

    // If validations pass, call the login function
    callLoginForUser(email, password);
    if (isDebugMode) {
      console.log({ email, password });
    }
  };

  const callLoginForUser = async (email, password) => {
    if (isDebugMode) console.log("hi from callLoginForUser");

    const baseUrl = process.env.REACT_APP_BASE_URL;
    if (isDebugMode) console.log("baseUrl =", baseUrl);

    try {
      const response = await fetch(`${baseUrl}api/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      });

      const textResponse = await response.text();
      if (isDebugMode) console.log("Response text:", textResponse);

      const jsonResponse = JSON.parse(textResponse);
      if (isDebugMode) console.log(jsonResponse);

      if (jsonResponse.success) {
        if (isDebugMode) console.log("Login success!");
        navigate('/dashboard', { state: { userId: jsonResponse.user_id, token: jsonResponse.token } });
      } else {
        if (isDebugMode) console.error("Login failed: wrong input");
        setErrorMessage("Login failed. Please check your email and password.");
        setErrorDialogOpen(true);
      }
    } catch (error) {
      if (isDebugMode) console.error('Error:', error);
      setErrorMessage("An error occurred. Please try again later.");
      setErrorDialogOpen(true);
    }
  };

  const handleForgotPassword = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSend = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/reset_user_password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });
      const jsonResponse = await response.json();

      if (jsonResponse.success) {
        if (isDebugMode) console.log("Reset link sent to email");
      } else {
        if (isDebugMode) console.error("Failed to send reset link");
      }
    } catch (error) {
      if (isDebugMode) console.error('Error:', error);
    }
    setOpen(false);
  };

  const handleSignUp = () => navigate('/signUp');
  const handleErrorDialogClose = () => setErrorDialogOpen(false);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
          <Avatar
            sx={{ m: 1, bgcolor: 'secondary.main', width: 100, height: 100 }} // Adjust size as needed
            alt="Logo"
            src={myLogo}
          />
        <Typography component="h1" variant="h5">
          כניסה לשירות
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            variant="standard"
            margin="normal"
            required
            fullWidth
            id="email"
            label="אימייל"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={emailError} // Set error state
            helperText={emailError ? "מייל לא חוקי" : ""} // Optional helper text
            sx={{ direction: 'ltr' }}
          />
          <TextField
            margin="normal"
            variant="standard"
            required
            fullWidth
            name="password"
            label="סיסמה"
            type={showPassword ? 'text' : 'password'}
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={passwordError} // Set error state
            helperText={passwordError ? "סיסמה קצרה מידי" : ""} // Optional helper text
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword((prev) => !prev)}>
                    {showPassword ? <VisibilityIcon color="primary" /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ direction: 'ltr' }}
          />
          <FormControlLabel
            sx={{ marginRight: 0 }}
            control={<Checkbox value="remember" color="primary" sx={{ paddingRight: 0 }} />}
            label="זכור אותי"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 2, mb: 2 }}
          >
            כניסה
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body1" onClick={handleForgotPassword}>
                איפוס סיסמה
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2" onClick={handleSignUp}>
                {"צור חשבון"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your email address. We will send you an email to reset your password.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSend}>Send</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={errorDialogOpen} onClose={handleErrorDialogClose}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrorDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
