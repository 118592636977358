import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import myLogo from '../../ez.jpeg'; // Adjust the path based on your project structure


const isDebugMode = process.env.REACT_APP_DEBUG === 'true';


export default function SignIn() {
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password');
    callLoginForPro(email, password);
if (isDebugMode)     console.log({
      email: email,
      password: password,
    });
  };

  const callLoginForPro = async (email, password) => {
if (isDebugMode)     console.log("hi from callLoginForPro");
if (isDebugMode)       console.log({email: email,password: password});
    try {
      const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/pro_login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
      const jsonResponse = await response.json();
if (isDebugMode)       console.log(jsonResponse);
      if (typeof jsonResponse === 'object' && jsonResponse !== null) {
        if (jsonResponse.success) {
if (isDebugMode)           console.log("success!!!!!!!!!!!!!!!!");
          navigate('/pro_trainings', { state: { proId: jsonResponse.pro_id, token: jsonResponse.token
                , training_type: jsonResponse.training_type} });
        } else {
if (isDebugMode)           console.error("wrong input");
        }
      } else {
if (isDebugMode)         console.error("Response is not JSON");
      }
    } catch (error) {
if (isDebugMode)       console.error('Error:', error);
    }
  };

  const handleForgotPassword = () => {
if (isDebugMode)     console.log("hello handleForgotPassword");
  };

  const handleSignUp = () => {
    navigate('/signup_pro');
  };

  return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar
            sx={{ m: 1, bgcolor: 'secondary.main', width: 100, height: 100 }} // Adjust size as needed
            alt="Logo"
            src={myLogo}
          />
          <Typography component="h1" variant="h5">
            כניסת מאמנים
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2" onClick={handleForgotPassword}>
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2" onClick={handleSignUp}>
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
  );
}
