import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  TextField, Button, Autocomplete, Select, MenuItem,
  InputLabel, FormControl
} from '@mui/material';

import { getTrainingTypesArray } from '../utils'; // Import the function to get training types


const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

const AddCardDialog = ({ open, onClose, adminId, token, cardId, dialogType, initialValues }) => {
const [amountPaidInput, setAmountPaidInput] = useState(initialValues?.amountPaid || initialValues?.price || 1600); // אתחול במחיר המלא
  const [price, setPrice] = useState(initialValues?.price || 1600);
  const [amountPaid, setAmountPaid] = useState(initialValues?.amountPaid || price);
  const [type, setType] = useState(initialValues?.type || 0); // Ensure type is a number
  const [lessonsCount, setLessonsCount] = useState(initialValues?.lessonsCount || 10);
  const [typePaid, setTypePaid] = useState(initialValues?.typePaid || 'מזומן');
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    if (open && dialogType !== 'payment') {
      fetchUsers();
    }

    if (dialogType === 'payment' && initialValues?.price) {
      setPrice(initialValues.price);
      const remainingAmount = initialValues.price - initialValues.amountPaid || 0; // Calculate remaining
      setAmountPaidInput(remainingAmount); // Initialize input state to remaining amount
    }
  }, [open, dialogType, initialValues]);

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ admin_id: adminId, token }),
      });

      const data = await response.json();
      if (data.success) {
        setUsers(data.users);
      } else {
if (isDebugMode)         console.error('Failed to fetch users');
      }
    } catch (error) {
if (isDebugMode)       console.error('Error fetching users:', error);
    }
  };

  const handleDone = async () => {
    try {
      const endpoint = dialogType === 'payment'
        ? 'api/admin_add_payment'
        : 'api/admin_add_card';

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          admin_id: adminId,
          token,
//          amount_paid: dialogType === 'payment' ? amountPaidInput : 0,
          amount_paid: amountPaidInput,
          price: price,
          lessons_count: dialogType === 'payment' ? 0 : lessonsCount,
          type: dialogType === 'payment' ? '' : type,
          user_id: dialogType === 'payment' ? null : selectedUser?.id,
          type_paid: typePaid,
          card_id: cardId,
          payment_description: dialogType === 'payment' ? "תשלום לכרטיסייה" : "פתיחת כרטיסייה",
        }),
      });

      const data = await response.json();
      if (data.success) {
        setAmountPaid(amountPaidInput);
        onClose(true);
      } else {
if (isDebugMode)         console.error('Failed to process request');
      }
    } catch (error) {
if (isDebugMode)       console.error('Error processing request:', error);
    }
  };

  const remainingAmount = dialogType === 'payment' ? Math.max(0, (price - amountPaid)) : null;

  const handleAmountPaidChange = (e) => {
      const value = e.target.value;
      const numericValue = value === '' ? 0 : parseFloat(value);

      if (value <= price) {
        setAmountPaidInput(numericValue);
      }

//    const value = e.target.value;
//    if (value === '' || /^[0-9]*$/.test(value)) { // Allow empty or numeric input
//      const numericValue = value === '' ? 0 : parseFloat(value);
//      // Update only if numericValue is within the allowed range
//      if (numericValue >= 0 && numericValue <= remainingAmount) {
//        setAmountPaidInput(numericValue);
//      }
//    }
  };

  const handleLessonsCountChange = (e) => {
    const value = e.target.value;
    if (value === '' || /^[0-9]*$/.test(value)) { // Allow empty or numeric input
      setLessonsCount(value === '' ? 0 : parseInt(value, 10)); // Convert to number
    }
  };

  return (
    <Dialog open={open} onClose={() => onClose(false)}>
      <DialogTitle>{dialogType === 'payment' ? 'הוספת תשלום לכרטיסייה' : 'הוספת אימון/כרטיסייה'}</DialogTitle>
      <DialogContent>
        {dialogType !== 'payment' && (
          <Autocomplete
            options={users}
            getOptionLabel={(option) =>
              `${option.name} (${option.email || 'לא זמין'}, ${option.phone || 'לא זמין'})`
            }
            onChange={(event, newValue) => setSelectedUser(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="שם" variant="outlined" required />
            )}
            value={selectedUser}
          />
        )}

        {dialogType !== 'payment' && (
          <FormControl fullWidth margin="normal">
            <InputLabel>סוג אימון</InputLabel>
            <Select
              value={type}
              onChange={(e) => setType(e.target.value)}
              label="סוג אימון"
            >
              {getTrainingTypesArray().map((trainingType) => (
                <MenuItem key={trainingType.id} value={trainingType.id}>
                  {trainingType.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {dialogType !== 'payment' && (
          <TextField
            label="כמות אימונים"
            variant="outlined"
            fullWidth
            margin="normal"
            type="number"
            value={lessonsCount}
            onChange={handleLessonsCountChange}
          />
        )}

        <TextField
          label={dialogType === 'payment' ? "עלות כרטיסייה" : "מחיר"}
          variant="outlined"
          fullWidth
          margin="normal"
          type="number"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          disabled={dialogType === 'payment'}
        />

        <TextField
          label="לתשלום"
          variant="outlined"
          fullWidth
          margin="normal"
          type="number"
//          onChange={(e) => setPrice(e.target.value)}
          value={amountPaidInput}
          onChange={handleAmountPaidChange}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} // Restricts input to numbers
        />

        <FormControl fullWidth margin="normal">
          <InputLabel>אופן התשלום</InputLabel>
          <Select
            value={typePaid}
            onChange={(e) => setTypePaid(e.target.value)}
            label="אופן התשלום"
          >
            <MenuItem value="מזומן">מזומן</MenuItem>
            <MenuItem value="אשראי">אשראי</MenuItem>
            <MenuItem value="ביט">ביט</MenuItem>
            <MenuItem value="פייבוקס">פייבוקס</MenuItem>
            <MenuItem value="שיק">שיק</MenuItem>
            <MenuItem value="העברה בנקאית">העברה בנקאית</MenuItem>
            <MenuItem value="אחר">אחר</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)}>ביטול</Button>
        <Button onClick={handleDone}>אישור</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCardDialog;
