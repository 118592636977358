import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';

const EditTraineeDialog = ({ open, onClose, traineeDetails, adminId, token }) => {
  // Set initial state with current trainee details
  const [name, setName] = useState(traineeDetails.name || '');
  const [team, setTeam] = useState(traineeDetails.team || '');
  const [year, setYear] = useState(traineeDetails.year || '');
  const [level, setLevel] = useState(traineeDetails.level || '');
  const [phone, setPhone] = useState(traineeDetails.phone || '');
  const [description, setDescription] = useState(traineeDetails.description || '');

  // Update state when dialog is opened or trainee details are updated
  useEffect(() => {
    if (open) {
      setName(traineeDetails.name || '');
//      setEmail(traineeDetails.email || '');
      setPhone(traineeDetails.phone || '');
      setTeam(traineeDetails.team || '');
      setYear(traineeDetails.year || '');
      setLevel(traineeDetails.level || '');
      setDescription(traineeDetails.description || '');
    }
  }, [open, traineeDetails]);

  const handleSave = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/edit_trainee', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          admin_id: adminId,
          token,
          trainee_id: traineeDetails.id,
          name,
          team,
          level,
          year,
          phone,
          description,
        }),
      });

      const data = await response.json();
      if (data.success) {
        // Notify parent component about the update
        onClose(true, adminId, token, traineeDetails.id);
      } else {
        // Optionally show error message to trainee
      }
    } catch (error) {
      // Optionally show error message to trainee
    }
  };

  return (
    <Dialog open={open} onClose={() => onClose(false)}>
      <DialogTitle>עריכת פרטי משתמש</DialogTitle>
      <DialogContent>
        <TextField
          label="שם"
          variant="outlined"
          fullWidth
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          label="טלפון"
          variant="outlined"
          fullWidth
          margin="normal"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <TextField
          label="קבוצה"
          variant="outlined"
          fullWidth
          margin="normal"
          value={team}
          onChange={(e) => setTeam(e.target.value)}
        />
        <TextField
          label="שנתון"
          variant="outlined"
          fullWidth
          margin="normal"
          value={year}
          onChange={(e) => setYear(e.target.value)}
        />

        <TextField
          label="רמה"
          variant="outlined"
          fullWidth
          margin="normal"
          value={level}
          onChange={(e) => setLevel(e.target.value)}
        />


        <TextField
          label="תיאור"
          variant="outlined"
          fullWidth
          margin="normal"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)}>ביטול</Button>
        <Button onClick={handleSave}>שמור</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditTraineeDialog;
