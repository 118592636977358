import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { useLocation as useRouterLocation, useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { useTheme } from '@mui/material';

import myLogo from '../../ez.jpeg'; // Adjust the path based on your project structure

const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

function AddTraining() {
  const navigate = useNavigate();
  const currentLocation = useRouterLocation();
  const { adminId, token, proId } = currentLocation.state || {};
  const [trainers, setTrainers] = useState([]);
  const [error, setError] = useState(null);
  const [availableTrainers, setAvailableTrainers] = useState([]);
  const [trainingTypes, setTrainingTypes] = useState([]);
  const theme = useTheme();

  const [formData, setFormData] = useState({
    date: '',
    time: '',
    location: '',
    trainer: '',
    description: '',
    type: '',
    maxParticipants: '1',
    year: -1,  // New field for year
    level: -1  // New field for level
  });

  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250,
      },
    },
  };

  useEffect(() => {
    const fetchTrainers = async () => {
      try {
        let response;

        if (adminId) {
          response = await fetch(`${process.env.REACT_APP_BASE_URL}api/get_admin_trainers`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ admin_id: adminId, token }),
          });
        } else if (proId) {
          response = await fetch(`${process.env.REACT_APP_BASE_URL}api/get_pro_trainer`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ pro_id: proId, token }),
          });
        }

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        if (data.success) {
          if (isDebugMode) console.log('data:', data);
          setTrainers(data.trainers);

          const uniqueTrainingTypes = new Map();
          data.trainers.forEach(trainer => {
            trainer.training_type_ids.forEach((id, index) => {
              if (!uniqueTrainingTypes.has(id)) {
                uniqueTrainingTypes.set(id, trainer.training_type_labels[index]);
              }
            });
          });

          setTrainingTypes(Array.from(uniqueTrainingTypes, ([id, label]) => ({ id, label })));
          setAvailableTrainers(data.trainers);
        } else {
          setError('Failed to fetch trainers');
        }
      } catch (error) {
        setError(`An error occurred: ${error.message}`);
      }
    };

    fetchTrainers();
  }, [adminId, proId, token]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData(prevState => {
      const updatedState = {
        ...prevState,
        [name]: value
      };

      if (name === 'type') {
        const selectedType = parseInt(value, 10);
        if (selectedType === 1) updatedState.maxParticipants = 1;
        else if (selectedType === 2) updatedState.maxParticipants = 2;
        else updatedState.maxParticipants = '10';

        const filteredTrainers = trainers.filter(trainer =>
          trainer.training_type_ids.includes(value)
        );
        setAvailableTrainers(filteredTrainers);
      }

      return updatedState;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const endpoint = adminId === undefined ? 'api/add_pro_training' : 'api/add_training';

    fetch(process.env.REACT_APP_BASE_URL + endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        admin_id: adminId,
        pro_id: proId,
        token,
        ...formData,
        ...(formData.type === '0' ? { year: formData.year, level: formData.level } : {}) // Include year and level only if type is '0'
      })
    })
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        if (adminId === undefined) {
          navigate('/pro_trainings', { state: { proId, token } });
        } else {
          navigate('/admin_trainings', { state: { adminId, token } });
        }
      } else {
        if (isDebugMode) console.error('Failed to add training:', data.message);
      }
    })
    .catch((error) => {
      if (isDebugMode) console.error('Error adding training:', error);
    });
  };

  return (
      <Container component="main" maxWidth="xs">
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 8 }}>
          <Avatar
            sx={{ m: 1, bgcolor: 'secondary.main', width: 100, height: 100 }} // Adjust size as needed
            alt="Logo"
            src={myLogo}
          />
          <Typography component="h1" variant="h5">
            הוספת אימון
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="date"
              label="תאריך"
              name="date"
              type="date"
              value={formData.date}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="time"
              label="שעה"
              name="time"
              type="time"
              value={formData.time}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="location"
              label="מיקום"
              name="location"
              value={formData.location}
              onChange={handleChange}
            />
            <InputLabel id="type-label">סוג אימון</InputLabel>
            <Select
              labelId="type-label"
              id="type"
              name="type"
              value={formData.type}
              onChange={handleChange}
              fullWidth
              margin="normal"
            >
              {trainingTypes.map((type) => (
                <MenuItem key={type.id} value={type.id}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
            <TextField
              margin="normal"
              fullWidth
              id="maxParticipants"
              label="מס' משתתפים"
              name="maxParticipants"
              type="number"
              value={formData.maxParticipants}
              onChange={handleChange}
            />

            <TextField
              margin="normal"
              fullWidth
              id="description"
              label="תיאור"
              name="description"
              value={formData.description}
              onChange={handleChange}
            />
            {/* Conditionally render Year and Level fields */}
            {formData.type === '0' && (
              <>
                <TextField
                  margin="normal"
                  fullWidth
                  id="year"
                  label="שנה"
                  name="year"
                  value={formData.year}
                  onChange={handleChange}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  id="level"
                  label="רמה"
                  name="level"
                  value={formData.level}
                  onChange={handleChange}
                />
              </>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              הוסף
            </Button>
          </Box>
        </Box>
      </Container>
  );
}

export default AddTraining;

