import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { getTrainingType } from '../utils'; // Import utility functions
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { useTheme } from '@mui/material/styles'; // Import useTheme

const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

// Helper function to format date
const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString(); // Adjust the format as needed
};

// Helper function to parse decimal strings
const parseDecimal = (decimalString) => {
  return parseFloat(decimalString).toFixed(2); // Ensure it's a fixed-point number
};

const getPreviousCards = async (userId, token) => {
  try {
    const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/user_archived_card', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_id: userId, token: token }), // Use user_id and token for fetch
    });

    const jsonResponse = await response.json();
    if (isDebugMode) {
      console.log("getPreviousCards jsonResponse");
      console.log(jsonResponse);
    }

    if (jsonResponse.success) {
      // Process the card data
      const processedCards = jsonResponse.user_archived_card.map(card => ({
        ...card,
        amount_paid: parseDecimal(card.amount_paid),
        creation_time: formatDate(card.creation_time),
        lessons_left: card.lessons_count - card.lessons_used, // Add this field for lessons left
      }));
      return processedCards;
    } else {
      if (isDebugMode) console.error("Error fetching previous cards");
    }
  } catch (error) {
    if (isDebugMode) console.error('Error:', error);
  }
  return [];
};

const PreviousCards = ({ userId, token }) => {
  const [cards, setCards] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate
  const theme = useTheme(); // Access the theme

  useEffect(() => {
    const fetchData = async () => {
      const previousCards = await getPreviousCards(userId, token);
      setCards(previousCards);
    };
    fetchData();
  }, [userId, token]);

  const handleRowClick = (card) => {
    if (isDebugMode) console.log('handleRowClick:', card); // Log the card data
    navigate('/user_card_details', { state: { userId: userId, token, cardId: card.id } });
  };

  return (
    <Box sx={{ p: 2, borderRadius: '4px', maxHeight: 300, overflowY: cards.length > 3 ? 'scroll' : 'visible' }}>
      <Box sx={{ mt: 2, overflowX: 'auto' }}> {/* Wrap table in a Box with overflowX */}
        {cards.length > 0 ? (
          <Box sx={{ overflowX: 'auto' }}>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <tbody>
                {cards.map(card => (
                  <tr
                    key={card.id}
                    onClick={() => handleRowClick(card)}
                    style={{
                      cursor: 'pointer',
                      borderBottom: `1px solid ${theme.palette.primary.main}`, // Add primary color border under each row
                    }}
                  >
                    <td style={{
                      textAlign: 'center',
                      padding: '8px',
                      color: theme.palette.primary.contrastText // Set text color to contrastText
                    }}>
                      {card.creation_time}
                    </td>
                    <td style={{
                      textAlign: 'center',
                      padding: '8px',
                      color: theme.palette.primary.contrastText // Set text color to contrastText
                    }}>
                      {getTrainingType(card.training_type)}
                    </td>
                    <td style={{
                      textAlign: 'center',
                      padding: '8px',
                      color: theme.palette.primary.contrastText // Set text color to contrastText
                    }}>
                      {card.lessons_count} {/* Display total lessons */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
        ) : (
          <Typography>לא קיימת היסטוריה</Typography>
        )}
      </Box>
    </Box>
  );
};

export default PreviousCards;
