import React from 'react';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { useLocation as useRouterLocation } from 'react-router-dom';
import ActiveCards from './ActiveCards';
import PreviousCards from './PreviousCards';
import NavBar from '../NavBar'; // Import the NavBar component
import myLogo from '../../ez.jpeg'; // Adjust the path based on your project structure
import { useTheme } from '@mui/material/styles'; // Import useTheme

const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

function Cards() {
  const currentLocation = useRouterLocation();
  const { userId, token } = currentLocation.state || {}; // Extract userId and token
  const [tabIndex, setTabIndex] = React.useState(2); // Initialize the tab index
  const theme = useTheme(); // Access the theme for colors

  React.useEffect(() => {
    if (isDebugMode) console.log('User ID:', userId);
    if (isDebugMode) console.log('Token:', token);
  }, [userId, token]);

  return (
    <Container component="main" maxWidth="md" className="users-page" sx={{ flex: 1, overflowY: 'auto', paddingBottom: 8 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 8 }}>
        <Avatar
          sx={{ m: 1, bgcolor: 'secondary.main', width: 100, height: 100 }} // Adjust size as needed
          alt="Logo"
          src={myLogo}
        />
        <Typography component="h1" variant="h5"></Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>
          <Box sx={{ mb: 4, width: '100%', maxWidth: 600 }}>
            <Typography variant="h6" align="center"></Typography>
            <ActiveCards userId={userId} token={token} />
          </Box>
          <Box sx={{ mb: 2, width: '100%', maxWidth: 600 }}>
            <Typography
              variant="h6"
              align="right"
              sx={{
                color: theme.palette.primary.contrastText, // Set text color to contrastText
                marginTop: 6, // Add margin bottom for spacing
                marginRight: 4, // Add margin right for spacing
              }}
            >
              כרטיסייות שנוצלו
            </Typography>
            <PreviousCards userId={userId} token={token} />
          </Box>
        </Box>
      </Box>
      <NavBar userId={userId} token={token} tabIndex={tabIndex} setTabIndex={setTabIndex} /> {/* Use the NavBar */}
    </Container>
  );
}

export default Cards;
