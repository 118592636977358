import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';


const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

function EditTrainingDialog({ open, onClose, training, onUpdate, adminId, proId, token }) {
  const [editFormData, setEditFormData] = useState({
    date: '',
    time: '',
    location: '',
    description: ''
  });

  useEffect(() => {
    if (training) {
      setEditFormData({
        date: training.date || '',
        time: training.time || '',
        location: training.location || '',
        description: training.description || ''
      });
    }
  }, [training]); // Update form data when training changes

  const handleDialogSubmit = () => {
    fetch(process.env.REACT_APP_BASE_URL + 'api/edit_training', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        admin_id: adminId,
        pro_id: proId,
        token: token,
        training_id: training.id,
        ...editFormData
      })
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          // Fetch the updated training details
          return fetch(process.env.REACT_APP_BASE_URL + 'api/get_training', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              admin_id: adminId,
              pro_id: proId,
              token: token,
              training_id: training.id
            })
          });
        } else {
if (isDebugMode)           console.error('Failed to update training:', data.message);
          throw new Error('Update failed');
        }
      })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          onUpdate(data.data); // Pass the updated training details to the parent component
          onClose(); // Close the dialog
        } else {
if (isDebugMode)           console.error('Failed to fetch updated training:', data.message);
        }
      })
      .catch(error => {
if (isDebugMode)         console.error('Error:', error);
      });
  };

  const handleEditFormChange = (e) => {
    const { name, value } = e.target;
    setEditFormData({
      ...editFormData,
      [name]: value
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Training</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="normal"
          required
          fullWidth
          id="date"
          label="Date"
          name="date"
          type="date"
          value={editFormData.date}
          onChange={handleEditFormChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="time"
          label="Time"
          name="time"
          type="time"
          value={editFormData.time}
          onChange={handleEditFormChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="location"
          label="Location"
          name="location"
          value={editFormData.location}
          onChange={handleEditFormChange}
        />
        <TextField
          margin="normal"
          fullWidth
          id="description"
          label="Description"
          name="description"
          value={editFormData.description}
          onChange={handleEditFormChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleDialogSubmit}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditTrainingDialog;
