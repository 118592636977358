import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditTrainingDialog from './EditTrainingDialog';
import ManageUsersDialog from './ManageUsersDialog';
import InvitedUsersTable from './InvitedUsersTable';
import ApproveUsersDialog from './ApproveUsersDialog';
import { useTheme } from '@mui/material';
import { formatTime, formatDate, getTrainingType } from '../utils';
import myLogo from '../../ez.jpeg'; // Adjust the path based on your project structure

const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

function TrainingDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const { adminId, proId, token, trainingId } = location.state || {};

  const [tabIndex, setTabIndex] = useState(0);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openManageUsersDialog, setOpenManageUsersDialog] = useState(false);
  const [training, setTraining] = useState(null);
  const [invitedUsers, setInvitedUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openApproveUsersDialog, setOpenApproveUsersDialog] = useState(false);
  const theme = useTheme();

  const fetchTrainingDetails = async () => {
    setLoading(true);
    setError('');
    try {
      const url = proId
        ? `${process.env.REACT_APP_BASE_URL}api/get_pro_training`
        : `${process.env.REACT_APP_BASE_URL}api/get_training`;
      const body = JSON.stringify({
        ...(proId ? { pro_id: proId } : { admin_id: adminId }),
        token,
        training_id: trainingId,
      });

      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body,
      });

      const data = await response.json();
      if (data.success) {
        setTraining(data.data[0] || null);
      } else {
        setError(data.message || 'Error fetching training details');
      }
    } catch (error) {
      setError('Error loading training details');
    } finally {
      setLoading(false);
    }
  };

  const fetchInvitedUsers = async () => {
    setLoading(true);
    setError('');
    try {
      const url = proId
        ? `${process.env.REACT_APP_BASE_URL}api/get_pro_users_to_training`
        : `${process.env.REACT_APP_BASE_URL}api/get_users_to_training`;
      const body = JSON.stringify({
        ...(proId ? { pro_id: proId } : { admin_id: adminId }),
        token,
        training_id: trainingId,
      });

      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body,
      });

      const data = await response.json();
      if (data.success) {
        setInvitedUsers(data.users || []);
      } else {
        setError(data.message || 'Error fetching invited users');
      }
    } catch (error) {
      setError('Error loading invited users');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTrainingDetails();
    fetchInvitedUsers();
  }, [adminId, proId, token, trainingId]);

  const handleUpdateTraining = (updatedTraining) => {
    setTraining(updatedTraining[0] || null);
  };

  const handleAddUsers = () => {
    setOpenManageUsersDialog(true);
  };

  const handleEdit = () => {
    setOpenEditDialog(true);
  };

  const handleDelete = () => {
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirm = async () => {
    setOpenDeleteDialog(false);
    try {
      const url = proId
        ? `${process.env.REACT_APP_BASE_URL}api/delete_pro_training`
        : `${process.env.REACT_APP_BASE_URL}api/delete_training`;
      const body = JSON.stringify({
        ...(proId ? { pro_id: proId } : { admin_id: adminId }),
        token,
        training_id: trainingId,
      });

      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body,
      });

      const data = await response.json();
      if (data.success) {
        navigate(-1);
      } else {
        setError(data.message || 'Error deleting training');
      }
    } catch (error) {
      setError('Error deleting training');
    }
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };

  const handleCommit = () => {
    setOpenApproveUsersDialog(true);
  };

  const refreshData = () => {
    fetchTrainingDetails();
    fetchInvitedUsers();
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!training) {
    return <Typography color="error">No training details available</Typography>;
  }

  const isCommitted = training.commited === 2;

  return (

      <Container component="main" maxWidth="md">
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 8 }}>
          <Avatar
            sx={{ m: 1, bgcolor: 'secondary.main', width: 100, height: 100 }} // Adjust size as needed
            alt="Logo"
            src={myLogo}
          />
          <Typography component="h1" variant="h5">
            פרטי אימון
          </Typography>

          <Box sx={{ width: '100%', maxWidth: 600 }}>
            <TableContainer component={Paper} sx={{ maxHeight: 300, overflowY: 'auto' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ textAlign: 'right' }}>תיאור</TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>מאמנים</TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>מיקום</TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>סוג האימון</TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>שעה</TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>תאריך</TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>ID</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {training && (
                    <TableRow>
                      <TableCell sx={{ textAlign: 'right' }}>{training.description || 'N/A'}</TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>{training.trainer}</TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>{training.location}</TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>{getTrainingType(training.training_type)}</TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>{formatTime(training.time)}</TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>{formatDate(training.date)}</TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>{training.id}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ mt: 2 }}>
              <Button variant="contained" sx={{ mr: 1 }} onClick={handleAddUsers} disabled={isCommitted}>
                הזמנת מתאמן
              </Button>
              <Button variant="contained" sx={{ mr: 1 }} onClick={handleEdit} disabled={isCommitted}>
                לערוך
              </Button>
              <Button variant="contained" sx={{ mr: 1 }} onClick={handleDelete} disabled={isCommitted}>
                למחוק
              </Button>
              <Button variant="contained" sx={{ mr: 1 }} onClick={handleCommit} disabled={isCommitted}>
                אישור ונעילת אימון
              </Button>
            </Box>
          </Box>
          <InvitedUsersTable
            invitedUsers={invitedUsers}
            setInvitedUsers={setInvitedUsers} // Pass the updater function
            token={token}
            adminId={adminId}
            trainingId={training.id}
          />
        </Box>

        <EditTrainingDialog
          open={openEditDialog}
          onClose={() => setOpenEditDialog(false)}
          training={training}
          onUpdate={handleUpdateTraining}
          adminId={adminId}
          proId={proId}
          token={token}
          onRefresh={refreshData}
        />

        <ManageUsersDialog
          open={openManageUsersDialog}
          onClose={() => {
            setOpenManageUsersDialog(false);
            refreshData();
          }}
          onSave={() => setOpenManageUsersDialog(false)}
          adminId={adminId}
          proId={proId}
          token={token}
          trainingId={trainingId}
          trainingType={training?.training_type}
        />

        <ApproveUsersDialog
          open={openApproveUsersDialog}
          onClose={() => setOpenApproveUsersDialog(false)}
          adminId={adminId}
          proId={proId}
          token={token}
          trainingId={trainingId}
          invitedUsers={invitedUsers}
        />

        <Dialog
          open={openDeleteDialog}
          onClose={handleDeleteCancel}
        >
          <DialogTitle>Delete Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this training?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteCancel}>Cancel</Button>
            <Button onClick={handleDeleteConfirm} color="primary">Yes</Button>
          </DialogActions>
        </Dialog>
      </Container>
  );
}

export default TrainingDetails;
