import React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { useLocation as useRouterLocation } from 'react-router-dom';
import CardTrainings from './UserCardTrainings';
import myLogo from '../../ez.jpeg'; // Adjust the path based on your project structure

const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

function UserTrainings() {
  const currentLocation = useRouterLocation();
  const { userId, token, cardId } = currentLocation.state || {};
  //    navigate('/card_details', { state: { adminId, token, cardId: card.id } });

  React.useEffect(() => {
    if (isDebugMode) console.log('User ID:', userId);
    if (isDebugMode) console.log('Token:', token);
    if (isDebugMode) console.log('cardId:', cardId);
  }, [userId, token, cardId]);

  return (

      <Container component="main" maxWidth="md" className="users-page" sx={{ flex: 1, overflowY: 'auto', paddingBottom: 8 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 8 }}>
          <Avatar
            sx={{ m: 1, bgcolor: 'secondary.main', width: 100, height: 100 }} // Adjust size as needed
            alt="Logo"
            src={myLogo}
          />


          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>
            <Box sx={{ mb: 2, width: '100%', maxWidth: 600 }}>
              <CardTrainings userId={userId} token={token} cardId={cardId} />
            </Box>
          </Box>
        </Box>
      </Container>
  );
}

export default UserTrainings;
