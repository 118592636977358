import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { formatTime, formatDate, getTrainingType } from '../utils';

const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

const AdminPreviousTrainings = ({ adminId, proId, token, showAll }) => {
  const [archivedTrainings, setArchivedTrainings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'asc' });
  const navigate = useNavigate(); // Hook to navigate

  useEffect(() => {
    const fetchArchivedTrainings = async () => {
          try {
        let response;
        const endpoint = adminId
          ? 'api/archived_trainings'
          : proId
          ? 'api/pro_archived_trainings'
          : null;

        if (endpoint) {
          response = await fetch(process.env.REACT_APP_BASE_URL + endpoint, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...(adminId ? { admin_id: adminId } : { pro_id: proId }), token }),
          });

          if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
          }

          const data = await response.json();
          if (data.success) {
            if (isDebugMode) console.log('data:', data);
            setArchivedTrainings(data.archived_trainings || []);
          } else {
            setError('Failed to fetch archived trainings');
          }
        } else {
          setError('No valid ID provided');
        }
      } catch (error) {
        setError(`An error occurred: ${error.message}`);
      } finally {
        setLoading(false);
      }
};
    fetchArchivedTrainings();
  }, [adminId, proId, token]);

  const handleRowClick = (training) => {
    if (proId) {
        navigate('/training_details', { state: { adminId, proId, token, trainingId: training.id } });
    } else {
        navigate('/training_details', { state: { adminId, token, trainingId: training.id } });
    }
  };

  const handleSort = (key) => {
    const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key, direction });
  };

  // Filter trainings based on showAll
  const filteredTrainings = showAll
    ? archivedTrainings
    : archivedTrainings.filter(training => [0, 1, 2].includes(training.training_type));

  const sortedTrainings = [...filteredTrainings].sort((a, b) => {
    const aValue = a[sortConfig.key];
    const bValue = b[sortConfig.key];

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return sortConfig.direction === 'asc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    } else if (sortConfig.key === 'date') {
      return sortConfig.direction === 'asc'
        ? new Date(aValue) - new Date(bValue)
        : new Date(bValue) - new Date(aValue);
    } else if (sortConfig.key === 'time') {
      return sortConfig.direction === 'asc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    } else if (sortConfig.key === 'id') {
      return sortConfig.direction === 'asc'
        ? aValue - bValue
        : bValue - aValue;
    } else {
      return 0; // No sorting applied for unknown key
    }
  });

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ textAlign: 'right' }} onClick={() => handleSort('id')}>
              ID
            </TableCell>
            <TableCell sx={{ textAlign: 'right' }} onClick={() => handleSort('date')}>
              תאריך
            </TableCell>
            <TableCell sx={{ textAlign: 'right' }} onClick={() => handleSort('time')}>
              שעה
            </TableCell>
            <TableCell sx={{ textAlign: 'right' }} onClick={() => handleSort('training_type')}>
              סוג האימון
            </TableCell>
            <TableCell sx={{ textAlign: 'right' }} onClick={() => handleSort('location')}>
              מיקום
            </TableCell>
            <TableCell sx={{ textAlign: 'right' }} onClick={() => handleSort('trainer')}>
              מאמנים
            </TableCell>
            <TableCell sx={{ textAlign: 'right' }} onClick={() => handleSort('description')}>
              תיאור
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedTrainings.map((training) => (
            <TableRow
              key={training.id}
              onClick={() => handleRowClick(training)}
              sx={{
                cursor: 'pointer',
                backgroundColor: training.commited === 2 ? '#f0f0f0' : 'inherit', // Grey background if commited is 2
              }}
            >
              <TableCell sx={{ textAlign: 'right' }}>{training.id}</TableCell>
              <TableCell sx={{ textAlign: 'right' }}>{formatDate(training.date)}</TableCell>
              <TableCell sx={{ textAlign: 'right' }}>{formatTime(training.time)}</TableCell>
              <TableCell sx={{ textAlign: 'right' }}>{getTrainingType(training.training_type)}</TableCell>
              <TableCell sx={{ textAlign: 'right' }}>{training.location}</TableCell>
              <TableCell sx={{ textAlign: 'right' }}>{training.trainers}</TableCell>
              <TableCell sx={{ textAlign: 'right' }}>{training.description || 'N/A'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AdminPreviousTrainings;
