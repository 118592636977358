import React, { useState, useEffect, useRef } from 'react';
import { useLocation as useRouterLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import { getTrainingType } from '../utils'; // Adjust the path as necessary
import EditUserDialog from './EditUserDialog';
import EditTraineeDialog from './EditTraineeDialog'; // Adjust the path as necessary

const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

// Style for tables to ensure uniform width
const StyledTableContainer = styled(TableContainer)(({ theme, maxWidth }) => ({
  width: maxWidth || '100%',
  maxWidth: '1200px', // Adjust as needed
  margin: '0 auto',
}));

// Helper function to format date
const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString(); // Adjust the format as needed
};

const fetchUserDetails = async (adminID, token, userID) => {
  try {
    const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/admin_user_details', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ admin_id: adminID, token: token, user_id: userID }),
    });
    const jsonResponse = await response.json();
    if (jsonResponse.success) {
      return jsonResponse.user_info[0] || {};
    } else {
if (isDebugMode)       console.error("Error fetching user details");
    }
  } catch (error) {
if (isDebugMode)     console.error('Error:', error);
  }
  return {};
};

const fetchTrainers = async (adminID, token, userId) => {
  try {
    const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/admin_get_trainees_for_user', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ admin_id: adminID, token: token, user_id: userId}),
    });
    const jsonResponse = await response.json();
    if (jsonResponse.success) {
      return jsonResponse.trainers_info || [];
    } else {
if (isDebugMode)       console.error("Error fetching trainers");
    }
  } catch (error) {
if (isDebugMode)     console.error('Error:', error);
  }
  return [];
};

const fetchActiveCards = async (adminID, token, userID) => {
  try {
    const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/admin_user_active_card', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ admin_id: adminID, token: token, user_id: userID, status: 'active' }),
    });
    const jsonResponse = await response.json();
    if (jsonResponse.success) {
      const processedCards = jsonResponse.user_active_card.map(card => ({
        ...card,
        creation_time: formatDate(card.creation_time),
      }));
      return processedCards;
    } else {
if (isDebugMode)       console.error("Error fetching active cards");
    }
  } catch (error) {
if (isDebugMode)     console.error('Error:', error);
  }
  return [];
};

const fetchNonActiveCards = async (adminID, token, userID) => {
  try {
    const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/admin_user_archive_cards', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ admin_id: adminID, token: token, user_id: userID, status: 'inactive' }),
    });
    const jsonResponse = await response.json();
    if (jsonResponse.success) {
      const processedCards = jsonResponse.user_archived_card.map(card => ({
        ...card,
        creation_time: formatDate(card.creation_time),
      }));
      return processedCards;
    } else {
if (isDebugMode)       console.error("Error fetching non-active cards");
    }
  } catch (error) {
if (isDebugMode)     console.error('Error:', error);
  }
  return [];
};

const fetchActiveTrainings = async (adminID, token, userID) => {
  try {
    const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/admin_user_active_training', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ admin_id: adminID, token: token, user_id: userID, status: 'active' }),
    });
    const jsonResponse = await response.json();
    if (jsonResponse.success) {
      const sortedTrainings = jsonResponse.user_active_training.sort((a, b) => b.training_session_id - a.training_session_id);
      return sortedTrainings;
    } else {
if (isDebugMode)       console.error("Error fetching active trainings");
    }
  } catch (error) {
if (isDebugMode)     console.error('Error:', error);
  }
  return [];
};

const fetchNonActiveTrainings = async (adminID, token, userID) => {
  try {
    const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/admin_user_archive_training', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ admin_id: adminID, token: token, user_id: userID, status: 'inactive' }),
    });
    const jsonResponse = await response.json();
    if (jsonResponse.success) {
      const sortedTrainings = jsonResponse.user_archived_training.sort((a, b) => b.training_session_id - a.training_session_id);
      return sortedTrainings;
    } else {
if (isDebugMode)       console.error("Error fetching non-active trainings");
    }
  } catch (error) {
if (isDebugMode)     console.error('Error:', error);
  }
  return [];
};



function AdminUserDetails() {
  const location = useRouterLocation();
  const { adminId, token, userId } = location.state || {};
  const [dialogOpen, setDialogOpen] = useState(false);
const [selectedUser, setSelectedUser] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [trainers, setTrainers] = useState([]);
  const [activeCards, setActiveCards] = useState([]);
  const [nonActiveCards, setNonActiveCards] = useState([]);
  const [activeTrainings, setActiveTrainings] = useState([]);
  const [nonActiveTrainings, setNonActiveTrainings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [maxTableWidth, setMaxTableWidth] = useState(0);
const [traineeDialogOpen, setTraineeDialogOpen] = useState(false);
const [selectedTrainee, setSelectedTrainee] = useState({});

  // Refs to access table containers
  const tableRefs = useRef([]);

//const handleEdit = () => {
//  if (isDebugMode) console.log("Edit button clicked");
//  // Set the current user details to the dialog
//  setSelectedUser(userDetails);
//  // Open the dialog
//  setDialogOpen(true);
//};


const handleResetPassword = () => {
    if (isDebugMode)   console.log("Reset Password button clicked");
  // Implement reset password functionality
};

const handleEditTrainee = (trainee) => {
  setSelectedTrainee(trainee);
  setTraineeDialogOpen(true);
};

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        setError(null);
        const user = await fetchUserDetails(adminId, token, userId);
        setUserDetails(user);
        const trainers = await fetchTrainers(adminId, token, userId);
        setTrainers(trainers);

        const activeCards = await fetchActiveCards(adminId, token, userId);
        setActiveCards(activeCards);
        const nonActiveCards = await fetchNonActiveCards(adminId, token, userId);
        setNonActiveCards(nonActiveCards);
        const activeTrainings = await fetchActiveTrainings(adminId, token, userId);
        setActiveTrainings(activeTrainings);
        const nonActiveTrainings = await fetchNonActiveTrainings(adminId, token, userId);
        setNonActiveTrainings(nonActiveTrainings);

      } catch (err) {
        setError("An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
  }, [adminId, token, userId]);

  // Calculate maximum table width
  useEffect(() => {
    const measureTables = () => {
      let maxWidth = 0;
      tableRefs.current.forEach(ref => {
        if (ref && ref.clientWidth > maxWidth) {
          maxWidth = ref.clientWidth;
        }
      });
      setMaxTableWidth(maxWidth);
    };

    measureTables();
    window.addEventListener('resize', measureTables);

    return () => window.removeEventListener('resize', measureTables);
  }, [trainers, activeCards, nonActiveCards, activeTrainings, nonActiveTrainings]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <Container component="main" maxWidth="md">
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 8 }}>
        <Typography component="h1" variant="h5">
          פרטי משתמש
        </Typography>
        <Box sx={{ marginTop: 4 }}>
          <StyledTableContainer component={Paper} maxWidth={maxTableWidth}>
            <Table ref={el => tableRefs.current[0] = el}>
              <TableBody>
                <TableRow>
                  <TableCell>תיאור</TableCell>
                  <TableCell>אימייל</TableCell>
                  <TableCell>טלפון</TableCell>
                  <TableCell>שם</TableCell>
                </TableRow>
                <TableRow onClick={() => {
        setSelectedUser(userDetails);
        setDialogOpen(true);
      }}>
                  <TableCell>{userDetails.description || ''}</TableCell>
                  <TableCell>{userDetails.email || ''}</TableCell>
                  <TableCell>{userDetails.phone || ''}</TableCell>
                  <TableCell>{userDetails.name || ''}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Box>
        <EditUserDialog
          open={dialogOpen}
          onClose={(updated, adminId, token, userId) => {
            setDialogOpen(false);
            if (updated) {
              // Fetch updated user details if necessary
              fetchUserDetails(adminId, token, userId).then(user => setUserDetails(user));
            }
          }}
          userDetails={selectedUser}
          adminId={adminId}
          token={token}
        />

        <EditTraineeDialog
          open={traineeDialogOpen}
          onClose={(updated) => {
            setTraineeDialogOpen(false);
            if (updated) {
              // Optionally fetch updated trainers if needed
              fetchTrainers(adminId, token, userId).then(trainers => setTrainers(trainers));
            }
          }}
          traineeDetails={selectedTrainee}
          adminId={adminId}
          token={token}
        />



        {/* Trainers Table */}
        <Box sx={{ marginTop: 4 }}>
          <Typography variant="h6" sx={{ textAlign: 'right' }}>רשימת מתאמנים</Typography>
          <StyledTableContainer component={Paper} maxWidth={maxTableWidth}>
            <Table ref={el => tableRefs.current[1] = el}>
              <TableHead>
                <TableRow>
                  <TableCell>תיאור</TableCell>
                  <TableCell>רמה</TableCell>
                  <TableCell>שנתון</TableCell>
                  <TableCell>קבוצה</TableCell>
                  <TableCell>טלפון</TableCell>
                  <TableCell>שם</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {trainers.map(trainer => (
                    <TableRow key={trainer.id} onClick={() => handleEditTrainee(trainer)}>
                    <TableCell>{trainer.description}</TableCell>
                    <TableCell>{trainer.level}</TableCell>
                    <TableCell>{trainer.year}</TableCell>
                    <TableCell>{trainer.team}</TableCell>
                    <TableCell>{trainer.phone}</TableCell>
                    <TableCell>{trainer.name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Box>

        {/* Active Cards Table */}
        <Box sx={{ marginTop: 4 }}>
          <Typography variant="h6" sx={{ textAlign: 'right' }}>כרטיסייות פעילות</Typography>
          <StyledTableContainer component={Paper} maxWidth={maxTableWidth}>
            <Table ref={el => tableRefs.current[2] = el}>
              <TableHead>
                <TableRow>
                  <TableCell>שולם</TableCell>
                  <TableCell>עלות</TableCell>
                  <TableCell>ניצול אימונים</TableCell>
                  <TableCell>מספר אימונים</TableCell>
                  <TableCell>סוג אימון</TableCell>
                  <TableCell>תאריך</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {activeCards.map(card => (
                  <TableRow key={card.id}>
                    <TableCell>{card.amount_paid}</TableCell>
                    <TableCell>{card.price}</TableCell>
                    <TableCell>{card.lessons_used}</TableCell>
                    <TableCell>{card.lessons_count}</TableCell>
                    <TableCell>{getTrainingType(card.training_type)}</TableCell>
                    <TableCell>{card.creation_time}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Box>

        {/* Non-Active Cards Table */}
        <Box sx={{ marginTop: 4 }}>
          <Typography variant="h6" sx={{ textAlign: 'right' }}>היסטוריית כרטיסייות</Typography>
          <StyledTableContainer component={Paper} maxWidth={maxTableWidth}>
            <Table ref={el => tableRefs.current[3] = el}>
              <TableHead>
                <TableRow>
                  <TableCell>שולם</TableCell>
                  <TableCell>עלות</TableCell>
                  <TableCell>ניצול אימונים</TableCell>
                  <TableCell>מספר אימונים</TableCell>
                  <TableCell>סוג אימון</TableCell>
                  <TableCell>תאריך</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {nonActiveCards.map(card => (
                  <TableRow key={card.id}>
                    <TableCell>{card.amount_paid}</TableCell>
                    <TableCell>{card.price}</TableCell>
                    <TableCell>{card.lessons_used}</TableCell>
                    <TableCell>{card.lessons_count}</TableCell>
                    <TableCell>{getTrainingType(card.training_type)}</TableCell>
                    <TableCell>{card.creation_time}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Box>

        {/* Active Trainings Table */}
        <Box sx={{ marginTop: 4 }}>
          <Typography variant="h6" sx={{ textAlign: 'right' }}>אימונים פעילים</Typography>
          <StyledTableContainer component={Paper} maxWidth={maxTableWidth}>
            <Table ref={el => tableRefs.current[4] = el}>
              <TableHead>
                <TableRow>
                  <TableCell>מיקום</TableCell>
                  <TableCell>מאמן</TableCell>
                  <TableCell>תיאור</TableCell>
                  <TableCell>שעה</TableCell>
                  <TableCell>תאריך</TableCell>
                  <TableCell>שחקן</TableCell>
                  <TableCell>סוג אימון</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {activeTrainings.map(training => (
                  <TableRow key={training.id}>
                    <TableCell>{training.location}</TableCell>
                    <TableCell>{training.trainer}</TableCell>
                    <TableCell>{training.description}</TableCell>
                    <TableCell>{training.time}</TableCell>
                    <TableCell>{formatDate(training.date)}</TableCell>
                    <TableCell>{training.trainee_name}</TableCell>
                    <TableCell>{getTrainingType(training.training_type)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Box>

        {/* Non-Active Trainings Table */}
        <Box sx={{ marginTop: 4 }}>
          <Typography variant="h6" sx={{ textAlign: 'right' }}>היסטוריית אימונים</Typography>
          <StyledTableContainer component={Paper} maxWidth={maxTableWidth}>
            <Table ref={el => tableRefs.current[5] = el}>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>מיקום</TableCell>
                  <TableCell>מאמן</TableCell>
                  <TableCell>תיאור</TableCell>
                  <TableCell>שעה</TableCell>
                  <TableCell>תאריך</TableCell>
                  <TableCell>שחקן</TableCell>
                  <TableCell>סוג אימון</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {nonActiveTrainings.map(training => (
                  <TableRow key={training.id}>
                    <TableCell>
                        {training.card_pending === 1 ? 'לא שולם' : training.card_pending}
                    </TableCell>
                    <TableCell>{training.location}</TableCell>
                    <TableCell>{training.trainer}</TableCell>
                    <TableCell>{training.description}</TableCell>
                    <TableCell>{training.time}</TableCell>
                    <TableCell>{formatDate(training.date)}</TableCell>
                    <TableCell>{training.trainee_name}</TableCell>
                    <TableCell>{getTrainingType(training.training_type)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Box>
      </Box>
    </Container>
  );
}

export default AdminUserDetails;
