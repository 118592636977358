import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';
const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

const EditUserDialog = ({ open, onClose, userDetails, adminId, token }) => {
  // Set initial state with current user details
  const [name, setName] = useState(userDetails.name || '');
  const [email, setEmail] = useState(userDetails.email || '');
  const [phone, setPhone] = useState(userDetails.phone || '');
  const [description, setDescription] = useState(userDetails.description || '');

  // Update state when dialog is opened or user details are updated
  useEffect(() => {
    if (open) {
      setName(userDetails.name || '');
      setEmail(userDetails.email || '');
      setPhone(userDetails.phone || '');
      setDescription(userDetails.description || '');
    }
  }, [open, userDetails]);

  const handleSave = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/edit_user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          admin_id: adminId,
          token,
          user_id: userDetails.id,
          name,
          email,
          phone,
          description,
        }),
      });

      const data = await response.json();
      if (data.success) {
        // Notify parent component about the update
        onClose(true, adminId, token, userDetails.id);
      } else {
if (isDebugMode)         console.error('Failed to save user details');
        // Optionally show error message to user
      }
    } catch (error) {
if (isDebugMode)       console.error('Error saving user details:', error);
      // Optionally show error message to user
    }
  };

  return (
    <Dialog open={open} onClose={() => onClose(false)}>
      <DialogTitle>עריכת פרטי משתמש</DialogTitle>
      <DialogContent>
        <TextField
          label="שם"
          variant="outlined"
          fullWidth
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          label="אימייל"
          variant="outlined"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="טלפון"
          variant="outlined"
          fullWidth
          margin="normal"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <TextField
          label="תיאור"
          variant="outlined"
          fullWidth
          margin="normal"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)}>ביטול</Button>
        <Button onClick={handleSave}>שמור</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditUserDialog;
