import * as React from 'react';
import { PieChart, Pie, Cell, Tooltip, Label } from 'recharts';
import { useLocation as useRouterLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material'; // Import MUI components
import './PieChartSection.css'; // Import the CSS file for styling
import { getTrainingTypesArray } from '../utils'; // Import the function to get training types
import { useTheme } from '@mui/material'; // Import useTheme

const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

// Function to fetch pie chart data
const getPieChartData = async (user_id, token) => {
  try {
    const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/user_active_card', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_id, token }),
    });
    const jsonResponse = await response.json();
    if (jsonResponse.success) {
      const groupedData = {};

      jsonResponse.user_active_card.forEach(card => {
        const { training_type, lessons_count, lessons_used } = card;

        if (!groupedData[training_type]) {
          groupedData[training_type] = { used: 0, remaining: 0 };
        }

        groupedData[training_type].used += lessons_used;
        groupedData[training_type].remaining += (lessons_count - lessons_used);
      });

      // Convert groupedData into the format needed for PieChart
      const formattedData = Object.keys(groupedData).reduce((acc, type) => {
        const { used, remaining } = groupedData[type];
        if (used > 0 || remaining > 0) {
          acc[type] = [
            { name: 'נוצלו', value: used },
            { name: 'נותרו', value: remaining }
          ];
        }
        return acc;
      }, {});

      return formattedData;
    } else {
if (isDebugMode)       console.error("API response error");
    }
  } catch (error) {
if (isDebugMode)     console.error('Error:', error);
  }
  return {};
};

// Custom center label component
const renderCenterLabel = (props) => {
  const { cx, cy, data } = props;
  const total = data.reduce((acc, entry) => acc + entry.value, 0);
  const used = data.find(entry => entry.name === 'נוצלו')?.value || 0;

  return (
    <text
      x={cx}
      y={cy}
      textAnchor="middle"
      dominantBaseline="middle"
      fill="#333"
      fontSize={12} // Adjusted font size
      fontWeight="bold"
    >
      {`${used}/${total}`}
    </text>
  );
};

const PieChartSection = () => {
  const [data, setData] = React.useState({});
  const location = useRouterLocation();
  const { userId, token } = location.state || {};
  const theme = useTheme(); // Access the theme

  React.useEffect(() => {
    const fetchData = async () => {
      const pieChartData = await getPieChartData(userId, token);
      setData(pieChartData);
    };
    fetchData();
  }, [userId, token]);

  const trainingTypes = getTrainingTypesArray();
  const activeTypes = Object.keys(data).map(type => parseInt(type));

  return (
    <div className="pie-chart-container">
      <div className={`pie-chart-row ${activeTypes.length === 1 ? 'centered' : ''}`}>
        {activeTypes.length === 0 ? (
          <Typography variant="body2" color="textSecondary" align="center">
            אין כרטיסיות פעילות!
          </Typography>
        ) : (
          activeTypes.map(type => (
            <div key={type} className="pie-chart-box" style={{ width: activeTypes.length === 1 ? '100%' : '30%' }}>
              <PieChart width={140} height={140}> {/* Adjusted width and height */}
                <Pie
                  data={data[type]}
                  dataKey="value"
                  outerRadius={60} // Adjusted outer radius
                  fill="#8884d8"
                  label={null} // Remove labels inside the chart
                  stroke="none" // Remove stroke
                  labelLine={false} // Remove lines connecting to the labels
                >
                  {data[type].map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={index === 0 ? theme.palette.pie_primary.main : theme.palette.pie_secondary.main} />
                  ))}
                  <Label
                    content={<renderCenterLabel data={data[type]} />}
                  />
                </Pie>
                <Tooltip />
              </PieChart>
              {data[type].length > 0 && (
                <Box sx={{ textAlign: 'center', mt: 2 }}>
                  <Typography variant="subtitle2">
                    {trainingTypes.find(t => t.id === type)?.label || 'לא ידוע'}
                  </Typography>
                  <Typography variant="caption">
                    {`נוצלו: ${data[type].find(entry => entry.name === 'נוצלו')?.value || 0} | נותרו: ${data[type].find(entry => entry.name === 'נותרו')?.value || 0}`}
                  </Typography>
                </Box>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default PieChartSection;
