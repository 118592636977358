import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

const ApproveUsersDialog = ({ open, onClose, adminId, proId, token, trainingId, invitedUsers }) => {
  const [filterName, setFilterName] = useState('');
  const [error, setError] = useState(null);

  const handleCommit = async () => {
    if (isDebugMode) console.log('Commit button pressed');

    try {
      const url = proId
        ? `${process.env.REACT_APP_BASE_URL}api/pro_commited_training`
        : `${process.env.REACT_APP_BASE_URL}api/admin_commited_training`;
      const body = proId
        ? JSON.stringify({ pro_id: proId, token, training_id: trainingId })
        : JSON.stringify({ admin_id: adminId, token, training_id: trainingId });

      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: body
      });

      const data = await response.json();

      if (data.success) {
        if (isDebugMode) console.log('Training successfully committed');
        onClose(); // Close dialog after success
      } else {
        if (isDebugMode) console.error(data.message || 'Failed to commit training');
      }
    } catch (error) {
      if (isDebugMode) console.error('Error committing training:', error);
    }
  };

  // Filter for committed trainees
  const filteredTrainees = invitedUsers
    .filter(trainee => trainee.committed === 1) // Only include committed trainees
    .filter(trainee => trainee.name.toLowerCase().includes(filterName.toLowerCase())); // Apply name filter

  const handleCloseError = () => setError(null);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>אימות לפני אישור</DialogTitle>
      <DialogContent>
        <TextField
          label="חפש לפי שם"
          variant="outlined"
          fullWidth
          value={filterName}
          onChange={(e) => setFilterName(e.target.value)}
        />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>קבוצה</TableCell>
                <TableCell>שם</TableCell>
                <TableCell>מתאמן</TableCell> {/* Changed ID to Running Number */}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredTrainees.map((trainee, index) => (
                <TableRow key={trainee.id}>
                  <TableCell>{trainee.team}</TableCell>
                  <TableCell>{trainee.name}</TableCell>
                  <TableCell>{index + 1}</TableCell> {/* Running number starts from 1 */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>בטל</Button>
        <Button onClick={handleCommit}>אשר</Button> {/* Call handleCommit on click */}
      </DialogActions>

      {error && (
        <Snackbar open={Boolean(error)} autoHideDuration={6000} onClose={handleCloseError}>
          <Alert onClose={handleCloseError} severity="error">{error}</Alert>
        </Snackbar>
      )}
    </Dialog>
  );
};

export default ApproveUsersDialog;
