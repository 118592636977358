import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { getTrainingType } from '../utils'; // Import utility functions
import { useTheme } from '@mui/material/styles'; // Import useTheme

const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

// Helper function to format date
const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString(); // Adjust the format as needed
};

// Helper function to parse decimal strings
const parseDecimal = (decimalString) => {
  return parseFloat(decimalString);
};

const getActiveCards = async (userId, token, navigate) => {
  try {
    const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/user_active_card', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_id: userId, token: token }), // Use user_id and token for fetch
    });
    const jsonResponse = await response.json();

    if (isDebugMode) {
      console.log("getActiveCards jsonResponse", jsonResponse);
    }

    if (jsonResponse.success) {
      // Process the card data
      const processedCards = jsonResponse.user_active_card.map(card => ({
        ...card,
        amount_paid: parseDecimal(card.amount_paid),
        creation_time: formatDate(card.creation_time),
        lessons_left: card.lessons_count - card.lessons_used, // Add this field for lessons left
      }));
      return processedCards;
    } else if (jsonResponse.message === 'Invalid token or user ID.') {
      navigate('/login'); // Redirect to login if token or user ID is invalid
    } else {
      if (isDebugMode) console.error("Error fetching active cards");
    }
  } catch (error) {
    if (isDebugMode) console.error('Error:', error);
  }
  return [];
};

const ActiveCards = ({ userId, token }) => {
  const [cards, setCards] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate
  const theme = useTheme(); // Access the theme

  useEffect(() => {
    const fetchData = async () => {
      const activeCards = await getActiveCards(userId, token, navigate);
      setCards(activeCards);
    };
    fetchData();
  }, [userId, token, navigate]);

  const handleRowClick = (card) => {
    if (isDebugMode) console.log('handleRowClick:', card); // Log the card data
    navigate('/user_card_details', { state: { userId: userId, token, cardId: card.id } });
  };

  return (
    <Box sx={{ p: 2, borderRadius: '4px', maxHeight: 300, overflowY: cards.length > 3 ? 'scroll' : 'visible' }}>
      <Box sx={{ mt: 2, overflowX: 'auto' }}> {/* Wrap table in a Box with overflowX */}
        {cards.length > 0 ? (
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <tbody>
              {cards.map(card => (
                <tr
                  key={card.id}
                  onClick={() => handleRowClick(card)}
                  style={{
                    cursor: 'pointer',
                    color: theme.palette.primary.contrastText, // Add text color
                    borderBottom: `1px solid ${theme.palette.primary.main}`, // Add primary color border
                  }}
                >
                  <td
                    style={{
                      textAlign: 'center',
                      padding: '8px',
                      borderBottom: `1px solid ${theme.palette.primary.main}`, // Same border under each row
                    }}
                  >
                    {card.creation_time}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                      padding: '8px',
                      borderBottom: `1px solid ${theme.palette.primary.main}`, // Same border under each row
                    }}
                  >
                    {getTrainingType(card.training_type)}
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                      padding: '8px',
                      borderBottom: `1px solid ${theme.palette.primary.main}`, // Same border under each row
                    }}
                  >
                    {card.lessons_used}/{card.lessons_count} {/* Display used/total lessons */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <Typography>No active cards available.</Typography>
        )}
      </Box>
    </Box>
  );
};

export default ActiveCards;
