import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import HomeIcon from '@mui/icons-material/Home';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';

const NavBar = ({ userId, token, tabIndex, setTabIndex }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    switch (newValue) {
      case 0:
        navigate('/dashboard', { state: { userId, token } });
        break;
      case 1:
        navigate('/trainings', { state: { userId, token } });
        break;
      case 2:
        navigate('/cards', { state: { userId, token } });
        break;
      case 3:
        navigate('/userinfo', { state: { userId, token } });
        break;
      default:
        break;
    }
  };

  return (
    <Box sx={{ width: '100%', position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: theme.palette.navBarBackground.main, boxShadow: 1 }}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="navigation tabs"
          TabIndicatorProps={{
            style: {
              backgroundColor: theme.palette.secondary.main, // This changes the color of the tab indicator (the active underline)
            },
          }}
        >
          <Tab
            icon={<HomeIcon />}
            label="בית"
            sx={{
              color: tabIndex !== 0 ? theme.palette.navBarBackground.contrastText : theme.palette.secondary.main, // Adjust text color when selected
            }}
          />
          <Tab
            icon={<FitnessCenterIcon />}
            label="אימונים"
            sx={{
              color: tabIndex !== 1 ? theme.palette.navBarBackground.contrastText : theme.palette.secondary.main,
            }}
          />
          <Tab
            icon={<CardMembershipIcon />}
            label="כרטיסיות"
            sx={{
              color: tabIndex !== 2 ? theme.palette.navBarBackground.contrastText : theme.palette.secondary.main,
            }}
          />
          <Tab
            icon={<PersonIcon />}
            label="איזור אישי"
            sx={{
              color: tabIndex !== 3 ? theme.palette.navBarBackground.contrastText : theme.palette.secondary.main,
            }}
          />
        </Tabs>
      </Box>
    </Box>
  );
};

export default NavBar;
