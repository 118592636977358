import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';

const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

function InvitedUsersTable({ invitedUsers, setInvitedUsers, token, adminId, trainingId }) {
  const [sortColumn, setSortColumn] = useState('name'); // Default sort by name
  const [sortOrder, setSortOrder] = useState('asc'); // Default ascending order

  // Debugging output
  if (isDebugMode) console.log('Invited Users in Table:', invitedUsers);
  if (isDebugMode) console.log('adminId:', adminId);
  if (isDebugMode) console.log('trainingId:', trainingId);

  // Sort function
  const sortedUsers = [...invitedUsers].sort((a, b) => {
    const aValue = a[sortColumn];
    const bValue = b[sortColumn];

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return sortOrder === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    }

    return sortOrder === 'asc' ? aValue - bValue : bValue - aValue;
  });

  const handleToggleCommitted = async (userId, currentStatus) => {
    // If the current status is 2, we do nothing
    if (currentStatus === 2) return;

    const newStatus = currentStatus === 1 ? 0 : 1; // Toggle between 0 and 1

    // Call the API to update the committed status
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/user_commited`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: userId,
          user_training_id: trainingId,
          admin_id: adminId,
          token: token,
          commit: newStatus === 1, // Send true if we are committing
        }),
      });

      const data = await response.json();
      if (data.success) {
        console.log('Commit status updated successfully:', data);

        // Update local state
        setInvitedUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.id === userId ? { ...user, committed: newStatus } : user
          )
        );
      } else {
        console.error('Failed to update commit status:', data.message);
      }
    } catch (error) {
      console.error('Error updating commit status:', error);
    }
  };

  const handleSort = (column) => {
    const isAscending = sortColumn === column && sortOrder === 'asc';
    setSortColumn(column);
    setSortOrder(isAscending ? 'desc' : 'asc');
  };

  if (!sortedUsers || sortedUsers.length === 0) {
    return <Typography color="error">No invited users available</Typography>;
  }

  return (
    <Box sx={{ width: '100%', maxWidth: 600, mt: 4 }}>
      <Typography variant="h6" align="center" sx={{ mb: 2 }}>מתאמנים שזומנו</Typography>
      <TableContainer component={Paper} sx={{ maxHeight: 300, overflowY: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ textAlign: 'right' }} onClick={() => handleSort('committed')}>אישור הגעה</TableCell>
              <TableCell sx={{ textAlign: 'right' }} onClick={() => handleSort('description')}>תיאור</TableCell>
              <TableCell sx={{ textAlign: 'right' }} onClick={() => handleSort('year')}>שנתון</TableCell>
              <TableCell sx={{ textAlign: 'right' }} onClick={() => handleSort('level')}>דרג</TableCell>
              <TableCell sx={{ textAlign: 'right' }} onClick={() => handleSort('team')}>קבוצה</TableCell>
              <TableCell sx={{ textAlign: 'right' }} onClick={() => handleSort('name')}>שם</TableCell>
              <TableCell sx={{ textAlign: 'right' }} onClick={() => handleSort('id')}>ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedUsers.map((user) => (
              <TableRow key={user.id}>
                <TableCell sx={{ textAlign: 'right' }}>
                  <Switch
                    checked={user.committed === 1 || user.committed === 2} // Toggle on if committed is 1 or 2
                    onChange={() => handleToggleCommitted(user.id, user.committed)}
                    color="primary"
                    disabled={user.session_committed === 2} // Disable only for committed 2
                  />
                </TableCell>
                <TableCell sx={{ textAlign: 'right' }}>{user.description}</TableCell>
                <TableCell sx={{ textAlign: 'right' }}>{user.year}</TableCell>
                <TableCell sx={{ textAlign: 'right' }}>{user.level}</TableCell>
                <TableCell sx={{ textAlign: 'right' }}>{user.team}</TableCell>
                <TableCell sx={{ textAlign: 'right' }}>{user.name}</TableCell>
                <TableCell sx={{ textAlign: 'right' }}>{user.id}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default InvitedUsersTable;
